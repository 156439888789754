@import './variables.less';

////////////////////////
// HUBSPOT OVERRIDES
////////////////////////

#hubspot-messages-iframe-container {
  opacity: 0;
  pointer-events: none;
}

.enable-chat-session {
  #hubspot-messages-iframe-container {
    opacity: 1;
    pointer-events: auto;
  }
}
