@import './variables.less';

////////////////////////
// ANT OVERRIDES
////////////////////////

.norby-page-drawer {
  max-width: 100vw;

  .ant-page-header {
    padding: 0 @padding-lg;
    height: @layout-header-height;
    border-bottom: @border-width-base solid @border-color-split;
    z-index: 99;

    .ant-page-header-heading {
      height: 100%;
      align-items: center;
    }

    .ant-page-header-heading-sub-title,
    .ant-page-header-heading-title {
      font-family: @font-family;
      font-size: @font-size-base;
      line-height: normal;
    }

    .ant-page-header-heading-title {
      font-weight: 500;
      white-space: nowrap;
    }
  }
}

.ant-upload-list-item-card-actions {
  display: flex;
}

.ant-alert {
  border-radius: @border-radius-lg;
}

.ant-skeleton-paragraph {
  margin: 0;
}

.ant-modal-content,
.ant-popover-inner,
.ant-card {
  overflow: hidden;
}

// Modals
.flush-modal {
  .ant-modal-content {
    border-radius: 0px !important;
  }
}

.ant-modal-title {
  color: @neutral-800;
  font-family: @feature-deck-font-family;
  font-weight: 300;
  .title-xxs;
}

.ant-modal-header {
  border: none;
  padding: 24px 24px 0px 24px;
}

.responsive-modal {
  @media screen and (max-width: @screen-sm) {
    bottom: 0px;
    height: 100vh;
    max-height: -webkit-fill-available;
    margin: 0px;
    max-width: 100vw;
    padding: 0px;
    top: 0px;
    width: 100vw !important;

    .ant-modal-content {
      border-radius: 0px !important;
      height: 100vh;
      max-height: -webkit-fill-available;
    }
  }
}

.popover-flush {
  .ant-popover-inner-content {
    padding: 0;
    max-height: 80vh;
    overflow-y: scroll;
  }
}

.ant-popover-inner-content {
  .ant-popover-buttons {
    display: flex;
  }
}

.ant-modal-confirm .ant-modal-confirm-btns,
.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.ant-modal-footer {
  padding: @padding-md @padding-lg;
}

.ant-spin-container {
  overflow: visible;
}

/*
    ng-zorro 12.1.0 bug fix / angular@12 – icon only buttons
  */
.ant-btn-icon-only {
  .anticon {
    display: inline-block;
  }
}

.anticon-check {
  svg {
    display: flex;
  }
}

/*
    ng-zorro 12.1.0 bug fix / angular@12 - dropdown menu items with icons
  */
.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-item > a > .anticon:first-child,
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-menu-submenu-title > a > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  min-width: @font-size-sm;
  margin-right: @margin-xs;
  font-size: @font-size-sm;
  vertical-align: -0.1em;
}
.ant-menu-title-content {
  display: flex;
}

.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: nowrap;
}

.ant-page-header-heading-sub-title {
  align-self: flex-end;
  flex: 1 1 auto;
}

.ant-page-header-heading-title {
  flex: 1 1 auto;
  font-family: @feature-deck-font-family;
  font-weight: 300;
  line-height: normal;
  white-space: normal;
  overflow: visible;
}

.ant-page-header-heading-extra {
  display: flex;
}

.ticket-options .ant-input-prefix {
  padding: 0 @padding-xss;
}

.ant-form-item {
  &:last-child {
    margin: 0 !important;
  }
}

.ant-select-item-option-content {
  display: flex;
  align-items: center;
}

.ant-card {
  .ant-card-actions,
  .ant-card-body > :last-child {
    margin-bottom: 0;
  }
}

.ant-btn,
a.ant-btn {
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: normal !important;
  padding-top: 0px;
  padding-bottom: 0px;

  &:hover {
    background-color: @neutral-700;
    border-color: @neutral-700;
    color: @white;
  }

  &:active {
    background-color: @neutral-200;
    border-color: @neutral-900;
    color: @neutral-900;
  }

  &:focus {
    box-shadow: 0 0 0 2px @primary-latte;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.icon-circle {
    padding: 10px 3.5px;
  }

  &.ant-btn-primary {
    &:hover {
      background-color: @neutral-800;
      border-color: @neutral-800;
    }

    &:active {
      background-color: @neutral-900;
      border-color: @neutral-900;
      color: @white;
    }

    &:focus {
      background-color: @dark-navy;
      border-color: @dark-navy;
    }

    &:disabled {
      background-color: @neutral-200;
      border-color: @neutral-600;
      color: @neutral-600;
    }
  }

  &.ant-btn-text {
    border-color: transparent;
    background-color: transparent;

    &:not(.ant-btn-dangerous) {
      color: @mustard;
    }

    &:hover:not(:disabled) {
      background-color: @mustard-200;
      border-color: @mustard-200;
    }

    &:active,
    &:focus {
      background-color: @mustard-400;
      border-color: @mustard-400;
    }

    &:disabled {
      color: @mustard-600;
    }
  }

  &.ant-btn-circle {
    border-radius: 50%;
    padding: 0;
  }
}

.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:focus {
  color: #696f8c;
  border-color: #696f8c;
}

.ant-tabs-tab {
  &.ant-tabs-tab-active {
    .ant-tabs-tab-btn,
    .ant-tabs-tab-btn a {
      color: @mustard;
    }
  }

  .ant-tabs-tab-btn,
  .ant-tabs-tab-btn a {
    color: @neutral-700;
    font-weight: 500;
  }

  &:not(.ant-tabs-tab-active) {
    .ant-tabs-tab-btn,
    .ant-tabs-tab-btn a {
      &:hover {
        color: @neutral-800;
      }
    }
  }

  .anticon {
    margin-right: 8px;
  }
}

.ant-switch {
  background-color: @beige-100;
  background-image: none;

  .ant-switch-handle:before {
    background-color: @sand-200;
    box-shadow: none;
  }

  &:hover {
    background-color: @beige-200;
  }

  &:active {
    background-color: @beige-300;
  }

  &.ant-switch-disabled {
    background-color: @neutral-100;
    opacity: 1;

    .ant-switch-handle:before {
      background-color: @neutral-400;
    }
  }
}

.ant-switch-checked {
  background-color: @mustard;
}

.ant-switch-checked:focus {
  box-shadow: none;
}

.ant-radio-wrapper {
  color: @neutral-900;

  .ant-radio {
    .ant-radio-inner {
      border-color: @mustard-400;
    }

    &:hover {
      .ant-radio-inner {
        border-color: @mustard;

        &:after {
          background-color: @mustard;
        }
      }
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: @mustard;

      &:after {
        background-color: @mustard;
      }
    }
  }
}

.ant-checkbox-wrapper {
  color: @neutral-900;

  &:hover {
    .ant-checkbox-inner {
      border-color: @neutral-500;
    }
  }

  &:active,
  &:focus {
    .ant-checkbox-inner {
      background-color: @neutral-100;
      border-color: @neutral-600;
    }
  }

  &.ant-checkbox-wrapper-checked {
    .ant-checkbox-inner {
      background-color: @mustard;
      border-color: @mustard;
    }

    &:hover {
      .ant-checkbox-inner {
        background-color: @mustard-800;
        border-color: @mustard-800;
      }
      .ant-checkbox-checked:after {
        border: none;
      }

      .ant-checkbox-disabled .ant-checkbox-inner {
        background-color: @neutral-200;
        border-color: @neutral-200;
      }
    }

    &:active,
    &:focus {
      .ant-checkbox-inner {
        background-color: @mustard-600;
        border-color: @mustard-600;
      }
    }
  }

  .ant-checkbox-inner {
    border-color: @neutral-400;
    border-radius: 4px;
  }

  .ant-checkbox-disabled + span {
    color: @neutral-500;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: @neutral-200;
    border-color: @neutral-200 !important;
  }
}

.ant-form-item-label {
  > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }
}

.ant-form-item-extra {
  margin-top: @margin-xs;
}

.ant-form-item-label > label.ant-form-item-required::after {
  content: 'Required';
  display: inline-block;
  margin-left: 8px;
  opacity: 0.4;
}

.ant-input:not(.ant-input-small),
.ant-input-group-wrapper:not(.ant-input-group-wrapper-sm)
  .ant-input-group-addon,
.ant-form-item .ant-input:not(.ant-input-small),
.ant-form-item .ant-picker,
.ant-form-item .ant-picker-input > input {
  font-size: 16px;
}

.ant-input-group-wrapper {
  border: none;
}

.ant-input-group-compact .ant-input {
  height: 48px;
}

.ant-input-wrapper {
  .ant-input-group-addon {
    border-color: @neutral-300;
    color: @neutral-800;
  }

  &:hover {
    .ant-input,
    .ant-input-group-addon {
      border-color: @neutral-500;
    }
  }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: @mustard;
  border-color: @mustard;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border-color: @mustard;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(
    .ant-picker-cell-range-hover-end
  )
  .ant-picker-cell-inner {
  background-color: @sand-200;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ):before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ):before,
.ant-picker-cell-in-view.ant-picker-cell-in-range:before {
  background-color: @mustard-400;
}

// hover over range
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover:before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover:before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover:before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ).ant-picker-cell-range-hover-start:before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ).ant-picker-cell-range-hover-end:before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start:before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end:before,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner:after,
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner:after {
  background-color: @mustard-600;
}

.ant-input-affix-wrapper,
.ant-picker,
.ant-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-color: @neutral-300;
  color: @neutral-800;
  padding: 10px 12px;

  &:hover {
    border-color: @neutral-500;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: @neutral-600;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: @neutral-600;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: @neutral-600;
    }
  }

  &:focus,
  &:active {
    border-color: @mustard;
    box-shadow: 0 0 0 2px @azure;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: @neutral-500;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: @neutral-500;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: @neutral-500;
  }

  &.ant-input-disabled {
    background: @neutral-50;
    border-color: @neutral-300;
    color: @neutral-500;
  }

  &.ant-input-sm {
    padding: 0 7px;
  }
}

input.ant-input.ant-input-lg {
  padding: 14px 16px;
}

// TO DO: REMOVE
textarea.ant-input.ant-input-lg {
  padding: 6px 11px;
}

.ant-input-search-button {
  height: 47px;
}

.ant-input-search-large .ant-input-search-button {
  height: 55px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: @neutral-300;
  color: @neutral-800;

  &:hover {
    border-color: @neutral-500;
  }

  &:focus,
  &:active {
    border-color: @mustard;
    box-shadow: 0 0 0 2px @azure;
  }
}

// default select

.ant-select-single:not(.ant-select-customize-input):not(.ant-select-sm) {
  font-size: 16px;

  .ant-select-selector {
    padding: 0 @padding-sm;
  }

  .ant-select-selector,
  .ant-select-selection-search,
  .ant-select-selection-search-input,
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    height: 48px;
    line-height: 48px;
  }
}

.ant-select-selection-placeholder {
  color: @neutral-500;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  transition: none;
}

// disabled select
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background: @neutral-50;
  border-color: @neutral-300;
  color: @neutral-500;
}

.ant-select-arrow {
  color: @neutral-900;
}

.ant-form-item-explain-error {
  font-size: 12px;
}

.ant-dropdown-menu-submenu-title {
  display: flex;
}

.ant-picker {
  width: 100%;
}

.ant-list {
  &.ant-list-lg {
    .ant-list-item {
      padding: @padding-lg 0;
    }
  }

  &.ant-list-sm {
    .ant-list-item {
      padding: @padding-sm 0;
    }
  }
}

.ant-collapse-item:not(.ant-collapse-item-active) {
  .ant-collapse-header {
    &:hover {
      background-color: @background-color-light;
    }
  }
}

.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: @padding-lg;
}

.ant-timeline-item {
  &:last-child {
    padding-bottom: 0;
  }

  .ant-timeline-item-head {
    height: 12px;
    width: 12px;
  }

  .ant-timeline-item-head-blue {
    border-color: @neutral-500;
  }
}

.ant-collapse,
.ant-collapse > .ant-collapse-item,
.ant-collapse .ant-collapse-content {
  border-color: @border-color-split;
}

.ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: @padding-lg;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: @padding-md (@padding-lg * 2) @padding-md @padding-lg;
}

.ant-upload-list-item {
  border-color: @border-color-split !important;
}

.ant-list-item-meta {
  flex: 1 1 auto;
  min-width: 250px;
}

.ant-menu {
  overflow: hidden;
}

.ant-menu-submenu .ant-menu-sub .ant-menu-item {
  margin-left: 13px;
  height: 30px;
  width: calc(100% - 13px);

  a {
    font-size: @font-size-sm;
  }
}

.ant-menu-item-group-title,
.ant-dropdown-menu-item-group-title {
  font-size: @font-size-sm;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-submenu {
  &.ant-menu-item-selected {
    > span > a {
      color: white;
    }
  }

  > * > a,
  > * > div {
    color: @neutral-600;

    &:hover {
      color: white;
    }
  }
}

.ant-dropdown-menu-item-group-list {
  margin: 0;
}

.ant-form-item-extra {
  a {
    text-decoration: underline;
  }
}

.ant-statistic-content-value {
  font-family: @feature-deck-font-family;
  font-weight: 300;
}

.ant-alert-content {
  font-weight: 500;
}

.ant-tag {
  border-color: @beige-100;
  color: @neutral-800;

  .ant-tag-close-icon {
    color: @neutral-500;

    &:hover {
      color: @neutral-600;
    }
  }
}

.ant-tag-success {
  background-color: @success-color-bg;
}

.ant-tag-warning {
  background-color: @warning-color-bg;
}

.ant-tag-processing {
  background-color: @info-color;
}

.ant-tag-error {
  background-color: @terracotta-400;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  background-color: @sand-200;
}

.ant-dropdown-menu-item:hover {
  background-color: @sand-200;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: @red-ui;

  &:hover {
    background-color: @red-ui;
  }
}

@media screen and (max-width: 480px) {
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small {
    flex-direction: row;
  }

  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small
    .ant-steps-item {
    flex: 0 0 auto;
    overflow: hidden;
  }

  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small
    > .ant-steps-item
    > .ant-steps-item-container {
    > .ant-steps-item-tail,
    > .ant-steps-item-content {
      display: none !important;
    }
  }

  // Bug on date range selector. Calendar overflows on small screen, stack them vertically
  .ant-picker-panels {
    display: flex;
    flex-direction: column;
  }
}

// override active tab bar color in inbox based on medium
.inbox-tab--sms {
  .ant-tabs-ink-bar {
    background-color: @green-ui;
  }
}

.inbox-tab--email {
  .ant-tabs-ink-bar {
    background-color: @neutral-800;
  }
}

.inbox-tab--twitter {
  .ant-tabs-ink-bar {
    background-color: @twitter-blue;
  }
}

.inbox-tab--instagram {
  .ant-tabs-ink-bar {
    background-color: @instagram-red;
  }
}

.campaign-overview-container {
  .ant-table-content {
    table {
      table-layout: auto !important;
    }
  }
}

.ant-table-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  z-index: 9;
}

// empty

nz-embed-empty {
  color: @text-color-secondary;
}

// ant nz-select
// .configurable-ant-select.ant-select-single:not(.ant-select-customize-input)
//   .ant-select-selector {
//   padding: 8px 12px;
// }

.configurable-ant-select nz-select-arrow span.anticon {
  width: 14px;
  height: 14px;
  margin: 0 2px;
  font-size: 14px;
}

.configurable-ant-select.ant-select-single.ant-select-open
  .ant-select-selection-item {
  color: #333;
}

.configurable-ant-select.ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.configurable-ant-select.ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  line-height: 30px;
}

.configurable-ant-select.ant-select-single.ant-select-show-arrow
  .ant-select-selection-search {
  display: flex;
  align-items: center;
}

.theme-selector .ant-select-selection-item,
.font-selector .ant-select-selection-item,
.layout-selector .ant-select-selection-item,
.theme-selector .ant-select-selection-placeholder,
.font-selector .ant-select-selection-placeholder,
.layout-selector .ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}

.grouped.select
  .configurable-ant-select.font-selector
  nz-select-top-control.ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ant-color-picker-inline {
  display: flex;
}

norby-drop-shadow-picker {
  .ant-color-picker-inline {
    > div {
      flex: 1;
      display: flex;
    }
  }
}
