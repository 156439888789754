@import './variables.less';

// UTILITY CLASSES

.ant-btn-animation {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.root-modal-container + .cdk-global-overlay-wrapper {
  overflow-y: auto;
  pointer-events: auto;
}

.cdk-global-scrollblock {
  overflow-y: hidden;
}

.list-item-hoverable {
  cursor: pointer;
  transition: all @animation-duration-fast @ease-out;

  &:hover {
    background-color: @item-hover-bg;
  }
}

// Root modal animations
.zoom-enter,
.zoom-leave,
.fade-enter,
.fade-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.zoom-enter.zoom-enter-active {
  -webkit-animation-name: norbyModalZoomIn;
  animation-name: norbyModalZoomIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.zoom-leave.zoom-leave-active {
  -webkit-animation-name: norbyModalZoomOut;
  animation-name: norbyModalZoomOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.zoom-enter {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.fade-enter.fade-enter-active {
  -webkit-animation-name: norbyModalFadeIn;
  animation-name: norbyModalFadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.fade-leave.fade-leave-active {
  -webkit-animation-name: norbyModalFadeOut;
  animation-name: norbyModalFadeOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.fade-enter {
  opacity: 0;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.fade-leave {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes norbyModalZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes norbyModalZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes norbyModalZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}

@keyframes norbyModalZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}

@-webkit-keyframes norbyModalFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes norbyModalFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes norbyModalFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes norbyModalFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// Hide recaptcha
.grecaptcha-badge {
  visibility: hidden;
}

@media screen and (max-width: @screen-xl) {
  .hidden-xl {
    display: none;
  }
}

@media screen and (max-width: @screen-lg) {
  .hidden-lg {
    display: none;
  }
}

@media screen and (max-width: @screen-md) {
  .hidden-md {
    display: none;
  }
}

@media screen and (max-width: @screen-sm) {
  .hidden-sm {
    display: none;
  }
}

@media screen and (max-width: @screen-xs) {
  .hidden-xs {
    display: none;
  }
}

@media screen and (min-width: (@screen-xxl + 1)) {
  .visible-xl {
    display: none;
  }
}

@media screen and (min-width: (@screen-xl + 1)) {
  .visible-lg {
    display: none;
  }
}

@media screen and (min-width: (@screen-lg + 1)) {
  .visible-md {
    display: none;
  }
}

@media screen and (min-width: (@screen-md + 1)) {
  .visible-sm {
    display: none;
  }
}

@media screen and (min-width: (@screen-sm + 1)) {
  .visible-xs {
    display: none;
  }
}

h1 {
  font-size: @h1-size;
}

h2 {
  font-size: @h2-size;
}

h3 {
  font-size: @h3-size;
}

h4 {
  font-size: @h4-size;
}

h5 {
  font-size: @h5-size;
}

h6 {
  font-size: @h6-size;
}

p,
ul,
ol {
  font-size: @font-size-base;
}

.send-block-list-item-drag {
  .ql-editor > div > a {
    cursor: pointer;
  }
  a {
    cursor: grab;
  }
}

.send-block-list-item-drag,
.send-detail-view-container {
  .rich-text-block {
    a {
      color: #06c;
      text-decoration: underline;
    }
    ul {
      padding-left: 1.5em;
      li {
        padding-left: 1.5em;
      }
      li:before {
        content: '\2022';
        margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: right;
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
      }
    }
  }
}
.view-email-block,
.edit-email-block {
  table,
  tbody,
  tr,
  td {
    border-collapse: separate;
    border-spacing: 0;
    border: none;
  }
}

.view-email-block table td {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
}

.edit-email-block table td {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-color: @mustard;
}

.mask-centered {
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
}
