@import './variables.less';

body {
  color: @text-color;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
select,
textarea {
  color: inherit;
  font-family: @font-family;
}

button {
  font-family: @font-family;
}

h2 {
  font-size: 21px;
}

h6 {
  font-weight: 500;
  font-size: @font-size-sm;
  text-transform: uppercase;
  color: @text-color-secondary;
}

h4,
h5 {
  font-weight: normal;
}

.title-lg {
  font-size: 84px;
  line-height: 98px;
}

.title-med {
  font-size: 62px;
  line-height: 74px;
}

.title-sm {
  font-size: 44px;
  line-height: 52px;
}

.title-xs {
  font-size: 36px;
  line-height: 42px;
}

.title-xxs {
  font-size: 28px;
  line-height: 32px;
}

//from ant designs, added here to be included in end-user and embeds apps
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 500;
  line-height: normal;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
