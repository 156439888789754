.cdk-drag-animating,
.item-placeholder-transition,
.cdk-drop-list-dragging .item,
.item-control {
  transition: transform @animation-duration-base @ease-out;
}

.cdk-drop-list-dragging {
  .item {
    cursor: grabbing;
  }
}

.item-handle {
  .first-handle {
    margin: 0 -@margin-xss;
  }

  .second-handle {
    margin: 0 -@margin-xs 0 -@margin-md;
  }
}

.cdk-drag-preview {
  box-shadow: @shadow-1-down;
  border-radius: @border-radius-base;
}

.item-handle,
.item-control {
  visibility: hidden;
}

.item:hover {
  .item-handle,
  .item-control {
    visibility: visible;
  }
}

@media (hover: none) {
  .item {
    .item-handle,
    .item-control {
      visibility: visible;
    }
  }
}
