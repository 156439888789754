@import './variables.less';

////////////////////////
// QUILL OVERRIDES
////////////////////////

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-readonly-body,
.ql-editor {
  img {
    max-width: 100%;
    display: inline;
  }

  ol {
    list-style-type: decimal;
    padding-left: 1.5em;
  }

  ul {
    list-style-type: disc;
    padding-left: 1.5em;
  }
}

.ql-editor p {
  font-size: 1rem;
}

.ql-container.ql-snow .ql-editor:before {
  color: @neutral-500 !important;
  opacity: 1;
}

:hover.ql-container.ql-snow :hover.ql-editor:before {
  color: @neutral-600 !important;
}
