/* grapick css */
.grp-wrapper {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==');
}
.grp-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: crosshair;
}
.grp-handler {
  width: 4px;
  margin-left: -2px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  height: 100%;
}
.grp-handler-close {
  color: rgba(0, 0, 0, 0.4);
  border-radius: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  text-align: center;
  width: 15px;
  height: 15px;
  margin-left: -5px;
  line-height: 10px;
  font-size: 21px;
  cursor: pointer;
}
.grp-handler-close-c {
  position: absolute;
  top: -17px;
}
.grp-handler-drag {
  background-color: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  width: 100%;
  height: 100%;
}
.grp-handler-selected .grp-handler-drag {
  background-color: rgba(255, 255, 255, 0.5);
}
.grp-handler-cp-c {
  display: none;
}
.grp-handler-selected .grp-handler-cp-c {
  display: block;
}
.grp-handler-cp-wrap {
  width: 15px;
  height: 15px;
  margin-left: -8px;
  border: 3px solid #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  border-radius: 100%;
  cursor: pointer;
}
.grp-handler-cp-wrap input[type='color'] {
  opacity: 0;
  cursor: pointer;
}
