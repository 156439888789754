@cdn-url: 'https://norby-fonts.s3.amazonaws.com/';
@cdn-url-2: 'https://d1ibfj737ra5p6.cloudfront.net/';

////////////////////////
// FONTS
////////////////////////
// BASIS

// basis-grotesque-bold-italic
@font-face {
  font-family: 'basis-grotesque';
  src: url('@{cdn-url-2}basis-grotesque-bold-italic.eot');
  src: url('@{cdn-url-2}basis-grotesque-bold-italic.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url-2}basis-grotesque-bold-italic.woff2') format('woff2'),
    url('@{cdn-url-2}basis-grotesque-bold-italic.woff') format('woff'),
    url('@{cdn-url-2}basis-grotesque-bold-italic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

// basis-grotesque-bold
@font-face {
  font-family: 'basis-grotesque';
  src: url('@{cdn-url-2}basis-grotesque-bold.eot');
  src: url('@{cdn-url-2}basis-grotesque-bold.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url-2}basis-grotesque-bold.woff2') format('woff2'),
    url('@{cdn-url-2}basis-grotesque-bold.woff') format('woff'),
    url('@{cdn-url-2}basis-grotesque-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

// basis-grotesque-medium
@font-face {
  font-family: 'basis-grotesque';
  src: url('@{cdn-url-2}basis-grotesque-medium.eot');
  src: url('@{cdn-url-2}basis-grotesque-medium.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url-2}basis-grotesque-medium.woff2') format('woff2'),
    url('@{cdn-url-2}basis-grotesque-medium.woff') format('woff'),
    url('@{cdn-url-2}basis-grotesque-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

// basis-grotesque-italic
@font-face {
  font-family: 'basis-grotesque';
  src: url('@{cdn-url-2}basis-grotesque-italic.eot');
  src: url('@{cdn-url-2}basis-grotesque-italic.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url-2}basis-grotesque-italic.woff2') format('woff2'),
    url('@{cdn-url-2}basis-grotesque-italic.woff') format('woff'),
    url('@{cdn-url-2}basis-grotesque-italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

// basis-grotesque-mono-bold-italic
@font-face {
  font-family: 'basis-grotesque-mono';
  src: url('@{cdn-url-2}basis-grotesque-mono-bold-italic.eot');
  src: url('@{cdn-url-2}basis-grotesque-mono-bold-italic.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url-2}basis-grotesque-mono-bold-italic.woff2') format('woff2'),
    url('@{cdn-url-2}basis-grotesque-mono-bold-italic.woff') format('woff'),
    url('@{cdn-url-2}basis-grotesque-mono-bold-italic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

// basis-grotesque-mono-bold
@font-face {
  font-family: 'basis-grotesque-mono';
  src: url('@{cdn-url-2}basis-grotesque-mono-bold.eot');
  src: url('@{cdn-url-2}basis-grotesque-mono-bold.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url-2}basis-grotesque-mono-bold.woff2') format('woff2'),
    url('@{cdn-url-2}basis-grotesque-mono-bold.woff') format('woff'),
    url('@{cdn-url-2}basis-grotesque-mono-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

// basis-grotesque-mono-italic
@font-face {
  font-family: 'basis-grotesque-mono';
  src: url('@{cdn-url-2}basis-grotesque-mono-italic.eot');
  src: url('@{cdn-url-2}basis-grotesque-mono-italic.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url-2}basis-grotesque-mono-italic.woff2') format('woff2'),
    url('@{cdn-url-2}basis-grotesque-mono-italic.woff') format('woff'),
    url('@{cdn-url-2}basis-grotesque-mono-italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

// basis-grotesque-mono-regular
@font-face {
  font-family: 'basis-grotesque-mono';
  src: url('@{cdn-url-2}basis-grotesque-mono-regular.eot');
  src: url('@{cdn-url-2}basis-grotesque-mono-regular.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url-2}basis-grotesque-mono-regular.woff2') format('woff2'),
    url('@{cdn-url-2}basis-grotesque-mono-regular.woff') format('woff'),
    url('@{cdn-url-2}basis-grotesque-mono-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// basis-grotesque-off-white-italic
@font-face {
  font-family: 'basis-grotesque-off-white';
  src: url('@{cdn-url-2}basis-grotesque-off-white-italic.eot');
  src: url('@{cdn-url-2}basis-grotesque-off-white-italic.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url-2}basis-grotesque-off-white-italic.woff2') format('woff2'),
    url('@{cdn-url-2}basis-grotesque-off-white-italic.woff') format('woff'),
    url('@{cdn-url-2}basis-grotesque-off-white-italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

// basis-grotesque-off-white
@font-face {
  font-family: 'basis-grotesque-off-white';
  src: url('@{cdn-url-2}basis-grotesque-off-white.eot');
  src: url('@{cdn-url-2}basis-grotesque-off-white.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url-2}basis-grotesque-off-white.woff2') format('woff2'),
    url('@{cdn-url-2}basis-grotesque-off-white.woff') format('woff'),
    url('@{cdn-url-2}basis-grotesque-off-white.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// basis-grotesque-regular
@font-face {
  font-family: 'basis-grotesque';
  src: url('@{cdn-url-2}basis-grotesque-regular.eot');
  src: url('@{cdn-url-2}basis-grotesque-regular.eot?#iefix')
      format('embedded-opentype'),
    url('@{cdn-url-2}basis-grotesque-regular.woff2') format('woff2'),
    url('@{cdn-url-2}basis-grotesque-regular.woff') format('woff'),
    url('@{cdn-url-2}basis-grotesque-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// FEATURE-DECK

@font-face {
  font-family: 'feature-deck';
  src: url('@{cdn-url-2}FeatureDeck-Regular-Web.woff2');
  src: url('@{cdn-url-2}FeatureDeck-Regular-Web.woff2') format('woff2'),
    url('@{cdn-url-2}FeatureDeck-Regular-Web.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'feature-deck';
  src: url('@{cdn-url-2}FeatureDeck-RegularItalic-Web.woff2');
  src: url('@{cdn-url-2}FeatureDeck-RegularItalic-Web.woff2') format('woff2'),
    url('@{cdn-url-2}FeatureDeck-RegularItalic-Web.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'feature-deck';
  src: url('@{cdn-url-2}FeatureDeck-Bold-Web.woff2');
  src: url('@{cdn-url-2}FeatureDeck-Bold-Web.woff2') format('woff2'),
    url('@{cdn-url-2}FeatureDeck-Bold-Web.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'feature-deck';
  src: url('@{cdn-url-2}FeatureDeck-BoldItalic-Web.woff2');
  src: url('@{cdn-url-2}FeatureDeck-BoldItalic-Web.woff2') format('woff2'),
    url('@{cdn-url-2}FeatureDeck-BoldItalic-Web.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'feature-deck';
  src: url('@{cdn-url-2}FeatureDeck-Extrabold-Web.woff2');
  src: url('@{cdn-url-2}FeatureDeck-Extrabold-Web.woff2') format('woff2'),
    url('@{cdn-url-2}FeatureDeck-Extrabold-Web.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'feature-deck';
  src: url('@{cdn-url-2}FeatureDeck-ExtraboldItalic-Web.woff2');
  src: url('@{cdn-url-2}FeatureDeck-ExtraboldItalic-Web.woff2') format('woff2'),
    url('@{cdn-url-2}FeatureDeck-ExtraboldItalic-Web.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'feature-deck';
  src: url('@{cdn-url-2}FeatureDeck-Light-Web.woff2');
  src: url('@{cdn-url-2}FeatureDeck-Light-Web.woff2') format('woff2'),
    url('@{cdn-url-2}FeatureDeck-Light-Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'feature-deck';
  src: url('@{cdn-url-2}FeatureDeck-LightItalic-Web.woff2');
  src: url('@{cdn-url-2}FeatureDeck-LightItalic-Web.woff2') format('woff2'),
    url('@{cdn-url-2}FeatureDeck-LightItalic-Web.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'feature-deck';
  src: url('@{cdn-url-2}FeatureDeck-Medium-Web.woff2');
  src: url('@{cdn-url-2}FeatureDeck-Medium-Web.woff2') format('woff2'),
    url('@{cdn-url-2}FeatureDeck-Medium-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'feature-deck';
  src: url('@{cdn-url-2}FeatureDeck-MediumItalic-Web.woff2');
  src: url('@{cdn-url-2}FeatureDeck-MediumItalic-Web.woff2') format('woff2'),
    url('@{cdn-url-2}FeatureDeck-MediumItalic-Web.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
