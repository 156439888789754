// -------- Colors ----------
@yellow: #ffe87f;
@green: #004c4c;
@dark-green: #003333;
@neutral: #fff9f2;
@blue: #8edeff;
@orange: #ff5f43;
@lilac: #dcb4f6;
@white: #fff;
@black: #000;
@pink: #ffc0cb;
@brown: #a88f72;
@light-gray: #f5fafa;
@light-gray-1: #f3f5f7;
@light-gray-2: #e7e7e7;
@light-gray-3: #9c9c9c;
@light-gray-4: #f2f2f2;
@light-gray-5: #fbfbfb;
@light-gray-6: #73716d;
@red-base: #ff5f43;

@primary-color: @black;
@info-color: @azure-800;
@success-color-bg: @primary-latte;
@success-color: @sand;
@processing-color: @blue;
@error-color: @secondary-terracotta;
@highlight-color: @yellow;
@warning-color: @red-ui;
@warning-color-bg: @light-red;
@normal-color: fade(@black, 20%);

@page-type-color: @orange;
@page-type-text-color: @white;

@event-type-color: @lilac;
@event-type-text-color: @white;

@link-type-color: @yellow;
@link-type-text-color: @white;

@drop-type-color: @green;
@drop-type-text-color: @white;

@newsletter-type-color: @green-ui;
@newsletter-type-text-color: @white;

@campaign-type-color: @blue;
@campaign-type-text-color: @green;

@twitter-blue: #1a92e9;

// brand colors
@neutral-900: #101840;
@neutral-800: #474d66;
@neutral-700: #696f8c;
@neutral-600: #8f9fb2;
@neutral-500: #c1c4d6;
@neutral-400: #d8dae5;
@neutral-300: #e6e8f0;
@neutral-200: #edeff5;
@neutral-100: #f9fafc;
@neutral-50: #fafbff;
@azure: #d5e1f0;
@azure-200: #fbfcfe;
@azure-400: #f3f6fa;
@azure-600: #ebf0f7;
@azure-800: #e7edf5;
@dark-navy: #140936;
@dark-navy-200: #e8e6ea;
@primary-latte: #f9fae8;
@coffee: #3f292e;
@mustard: #767024;
@mustard-200: #f1f1ea;
@mustard-400: #d6d4bf;
@mustard-600: #bab896;
@mustard-800: #9e9a6c;
@sand-800: #eadccb;
@sand-600: #f3eae1;
@sand-400: #f6efe9;
@sand-200: #fcfaf7;
@sand: #e5cbb2;
@beige-100: #ebe8e4;
@beige-200: #e5e0d6;
@beige-300: #e2dac9;
@green-ui: #52bd94;
@red-ui: #d14343;
@instagram-red: #ea7397;
@light-red: #fff6f5;
@dark-red: #871400;
@secondary-terracotta: #da6648;
@terracotta-200: #f4e1db;
@terracotta-400: #eed2c9;
@terracotta-600: #e4b5a7;
@terracotta-800: #d99883;

// Base Scaffolding Variables
// ---

@body-background: @white; // Background color for `<body>`
@component-background: @white; // Base background color for most components
@font-family: @basis-font-family;
@code-family: monospace;
@feature-deck-font-family:
  'feature-deck',
  serif,
  -apple-system,
  Roboto;
@basis-font-family:
  'basis-grotesque',
  'Noto Sans',
  -apple-system,
  sans-serif;
@text-color: @neutral-900;
@text-color-secondary: @neutral-600;
@text-color-inverse: @white;
@icon-color: inherit;
@icon-color-hover: fade(@black, 75%);
@heading-color: @neutral-900;
@heading-color-dark: fade(@white, 100%);
@text-color-dark: fade(@white, 85%);
@text-color-secondary-dark: @neutral-600;
@text-selection-bg: @primary-color;
@font-variant-base: tabular-nums;
@font-feature-settings-base: 'tnum';
@font-size-base: 14px;
@font-size-logo: 24px;
@font-size-lg: (@font-size-base + 2px);
@font-size-xl: (@font-size-base + 4px);
@font-size-sm: 12px;
@font-size-xs: 10px;
@h1-size: 28px;
@h2-size: 21px;
@h3-size: 16.38px;
@h4-size: 14px;
@h5-size: 11.62px;
@h6-size: 9.38px;
@heading-1-size: ceil(@font-size-base * 2.71);
@heading-2-size: ceil(@font-size-base * 2.14);
@heading-3-size: ceil(@font-size-base * 1.78);
@heading-4-size: ceil(@font-size-base * 1.42);
// https://github.com/ant-design/ant-design/issues/20210
@line-height-base: 1.5715;
@border-radius-base: 4px;
@border-radius-sm: 4px;
@border-radius-lg: 8px;

// vertical paddings
@padding-xlg: 40px;
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small

// vertical padding for all form controls
@control-padding-horizontal: @padding-sm;
@control-padding-horizontal-sm: @padding-xs;

// vertical margins
@margin-xlg: 40px;
@margin-lg: 24px; // containers
@margin-md: 16px; // small containers and buttons
@margin-sm: 12px; // Form controls and items
@margin-xs: 8px; // small items
@margin-xss: 4px; // more small

// height rules
@height-base: 36px;
@height-lg: 44px;
@height-sm: 24px;

// Media queries breakpoints
// Extra small screen / phone
@screen-xs: 480px;
@screen-xs-min: @screen-xs;

// Small screen / tablet
@screen-sm: 576px;
@screen-sm-min: @screen-sm;

// Medium screen / desktop
@screen-md: 768px;
@screen-md-min: @screen-md;

// Large screen / wide desktop
@screen-lg: 992px;
@screen-lg-min: @screen-lg;

// Extra large screen / full hd
@screen-xl: 1200px;
@screen-xl-min: @screen-xl;

// Extra extra large screen / large desktop
@screen-xxl: 1600px;
@screen-xxl-min: @screen-xxl;

// provide a maximum
@screen-xs-max: (@screen-sm-min - 1px);
@screen-sm-max: (@screen-md-min - 1px);
@screen-md-max: (@screen-lg-min - 1px);
@screen-lg-max: (@screen-xl-min - 1px);
@screen-xl-max: (@screen-xxl-min - 1px);

// Layout
@layout-body-background: @white; // #fbfbfb;
@layout-header-background: @white;
@layout-header-height: 56px;
@layout-header-padding: 0 50px;
@layout-header-color: @yellow;
@layout-footer-padding: 24px 50px;
@layout-footer-background: @layout-body-background;
@layout-sider-background: @neutral-900;
@layout-trigger-height: 48px;
@layout-trigger-background: #002140;
@layout-trigger-color: #fff;
@layout-zero-trigger-width: 36px;
@layout-zero-trigger-height: 42px;

// Layout light theme
@layout-sider-background-light: @sand-200;
@layout-trigger-background-light: #fff;
@layout-trigger-color-light: @text-color;

@image-thumbnail-width: 96px;
@item-hover-bg: #f5f5f5;

// The background colors for active and hover states for things like
// list items or table cells.
@item-active-bg: @mustard-200;

// Border color
// @border-color-base: hsv(0, 0, 85%); // base border outline a component
// @border-color-split: hsv(0, 0, 94%); // split border inside a component
@border-color-base: @neutral-400; // base border outline a component
@border-color-split: @neutral-200; // split border inside a component
@border-color-inverse: @white;
@border-width-base: 1px; // width of the border for a component
@border-width-thick: 2px; // width of the border for a component
@border-style-base: solid; // style of a components border

// Shadow
@shadow-color: rgba(0, 0, 0, 0.15);
@shadow-color-inverse: @component-background;
@box-shadow-base: @shadow-2;
@shadow-1-up:
  0 -6px 16px -8px rgba(0, 0, 0, 0.08),
  0 -9px 28px 0 rgba(0, 0, 0, 0.05),
  0 -12px 48px 16px rgba(0, 0, 0, 0.03);
@shadow-2-up:
  0 -12px 16px -8px rgba(0, 0, 0, 0.08),
  0 -18px 28px 0 rgba(0, 0, 0, 0.05),
  0 -24px 48px 16px rgba(0, 0, 0, 0.03);
@shadow-1-down:
  0 6px 16px -8px rgba(0, 0, 0, 0.08),
  0 9px 28px 0 rgba(0, 0, 0, 0.05),
  0 12px 48px 16px rgba(0, 0, 0, 0.03);
@shadow-1-left:
  -6px 0 16px -8px rgba(0, 0, 0, 0.08),
  -9px 0 28px 0 rgba(0, 0, 0, 0.05),
  -12px 0 48px 16px rgba(0, 0, 0, 0.03);
@shadow-1-right:
  6px 0 16px -8px rgba(0, 0, 0, 0.08),
  9px 0 28px 0 rgba(0, 0, 0, 0.05),
  12px 0 48px 16px rgba(0, 0, 0, 0.03);
@shadow-2:
  0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05);
@shadow-3:
  0 6px 6px -4px rgba(0, 0, 0, 0.18),
  0 12px 16px 0 rgba(0, 0, 0, 0.12),
  0 18px 28px 8px rgba(0, 0, 0, 0.08);

// Skeleton
// ---
@skeleton-color: rgba(190, 190, 190, 0.2);
@skeleton-to-color: shade(@skeleton-color, 5%);
@skeleton-paragraph-margin-top: 28px;
@skeleton-paragraph-li-margin-top: @margin-md;
@skeleton-paragraph-li-height: 16px;
@skeleton-title-height: 16px;
@skeleton-title-paragraph-margin-top: @margin-lg;

@header-placeholder-color: #ddd;
@header-avatar-size: 120px;
@header-banner-height: 120px;

@wide-page-width: 980px;
@narrow-page-width: 550px;

// Animation
@ease-base-out: cubic-bezier(0.7, 0.3, 0.1, 1);
@ease-base-in: cubic-bezier(0.9, 0, 0.3, 0.7);
@ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
@ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
@ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
@ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46);
@ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6);
@ease-in-out-back: cubic-bezier(0.71, -0.46, 0.29, 1.46);
@ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
@ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34);
@ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
@ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
@ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
@ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

// Animation
@animation-duration-slow: 0.3s; // Modal
@animation-duration-base: 0.2s;
@animation-duration-fast: 0.1s; // Tooltip

@keyframes animation-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Outline
@outline-blur-size: 0;
@outline-width: 0px;
@outline-color: @primary-color;
@outline-fade: 20%;

@background-color-light: @neutral-100; // Background of header and selected item
@background-color-base: @neutral-200; // Default grey background color

// Disabled states
@disabled-color: fade(#000, 25%);
@disabled-bg: @background-color-base;
@disabled-color-dark: fade(#fff, 35%);

// MIXINS

.blur(@color) {
  background-color: rgba(@color, 0.72);
  -webkit-backdrop-filter: saturate(1.8) blur(20px);
  backdrop-filter: saturate(1.8) blur(20px);
}
