@import '../../../node_modules/ng-zorro-antd/ng-zorro-antd.less';

@import '../../../styles/ant-theme.less';
@import '../../../styles/typography.less';
@import '../../../styles/utility.less';

@import '../../../styles/ant-overrides.less';
@import '../../../styles/drag-and-drop.less';
@import '../../../styles/feather-overrides.less';
@import '../../../styles/hubspot-overrides.less';
@import '../../../styles/quill-overrides.less';
@import '../../../styles/ngx-slider-overrides.less';
@import '../../../styles/grapick.less';
@import '../../../styles/grapick-overrides.less';
@import '../../../styles/svg-overrides.less';
@import '../../../styles/tailwind/tailwind-output-super-admin.css';

@import '@angular/cdk/overlay-prebuilt.css';

.ant-menu-item-group-title {
  margin-top: 0;
}
