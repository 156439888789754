/* ngx-slider customization */

.custom-slider .ngx-slider .ngx-slider-bar {
  background: #f5f5f5;
  height: 2px;
}

.custom-slider .ngx-slider .ngx-slider-selection {
  background: #101840;
  height: 4px;
}

.custom-slider .ngx-slider .ngx-slider-pointer {
  width: 16px;
  height: 16px;
  top: auto;
  bottom: 0;
  background-color: #fff;
  border: solid 2px #101840;
  border-radius: 50%;
}

.custom-slider .ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.custom-slider .ngx-slider .ngx-slider-pointer {
  background-color: #fff;
  top: -6px;
  border-radius: 50%;
}

.custom-slider .ngx-slider .ngx-slider-bubble {
  bottom: 14px;
  font-weight: normal;
  font-size: 10px;
  background-color: #101840;
  color: #fff;
  border: solid 2px #101840;
  border-radius: 5px;
}

.custom-slider .ngx-slider .ngx-slider-limit {
  font-weight: bold;
  font-size: 10px;
  color: #101840;
}

.custom-slider .ngx-slider .ngx-slider-tick {
  width: 0px;
  height: 0px;
  margin-left: 4px;
  border-radius: 0;
  background: #bbbbbb;
  top: -1px;
}

.custom-slider .ngx-slider .ngx-slider-tick .ngx-slider-tick-value {
  font-size: 10px;
  color: #bbbbbb;
}

.custom-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: #101840;
}

.ngx-slider.animate .ngx-slider-bubble.ngx-slider-limit {
  border: 0px;
}

/* end of ngx-slider customization */

/* ngx-slider customization size = small*/

.custom-slider-small .ngx-slider .ngx-slider-bar {
  background: #f5f5f5;
  height: 1px;
}

.custom-slider-small .ngx-slider .ngx-slider-selection {
  background: #101840;
  height: 2px;
}

.custom-slider-small .ngx-slider .ngx-slider-pointer {
  width: 8px;
  height: 8px;
  top: auto;
  bottom: 0;
  background-color: #fff;
  border: solid 2px #101840;
  border-radius: 50%;
}

.custom-slider-small .ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.custom-slider-small .ngx-slider .ngx-slider-pointer {
  background-color: #fff;
  top: -3px;
  border-radius: 50%;
}

.custom-slider-small .ngx-slider .ngx-slider-bubble {
  bottom: 5px;
  font-weight: normal;
  font-size: 9px;
  /* background-color: #101840; */
  color: #555;
  /* border: solid 2px #101840;
  border-radius: 5px; */
}

.custom-slider-small .ngx-slider .ngx-slider-limit {
  font-weight: bold;
  font-size: 10px;
  color: #101840;
}

.custom-slider-small .ngx-slider .ngx-slider-tick {
  width: 0px;
  height: 0px;
  margin-left: 4px;
  border-radius: 0;
  background: #bbbbbb;
  top: -1px;
}

.custom-slider-small .ngx-slider .ngx-slider-tick .ngx-slider-tick-value {
  font-size: 10px;
  color: #bbbbbb;
}

.custom-slider-small .ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: #101840;
}

.ngx-slider.animate .ngx-slider-bubble.ngx-slider-limit {
  border: 0px;
}

/* end of ngx-slider customization */
