/* grapick overrides */

.grp-wrapper {
  height: 50px !important;
}

.grp-preview {
  height: 50px !important;
}

.grp-handler {
  display: block !important;
}

.grp-handler-cp-c {
  display: block !important;
}

.grp-handler-cp-wrap {
  width: 24px !important;
  height: 24px !important;
  border-radius: 5px;
}
