/* Override base SVG style to work with Ant Design */
svg {
  vertical-align: initial;
}

img,
video {
  max-width: initial;
  height: initial;
}
